import * as React from 'react'
import { useEffect, useState, useRef } from 'react'

//assets
import heroBg from '../../../assets/images/brands/bee-kind/hero.png'
import heroBgMobile from '../../../assets/images/brands/bee-kind/hero-mobile.png'
import heroBgMobile2 from '../../../assets/images/brands/bee-kind/hero-mobile2.png'

import { isElementInViewport } from '../../functions'

const BeeKindHero = ( { title, description, logoUrl, logoAlt } ) => {
    const [isMobile, setIsMobile] = useState(false)
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)

    //choose the screen size
    const handleResize = () => {
        if(window.innerWidth < 992){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
    }

     //create an event listener
     useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)

        if(isElementInViewport(ref.current)){
            setIsInView(true)
        }
    }, [])

    return(
        <>
            <section
                className={(isInView)? 'brand-pg__hero d-flex in-view' : 'brand-pg__hero d-flex justify-content-center align-items-center'}
                ref={ref}
                >
                <div className='brand-pg__hero-bg' style={{backgroundImage: `url(${(isMobile)? heroBgMobile : heroBg})`}}></div>
                <div className='brand-pg__hero-content d-flex text-center slice-up container-fluid slide-up'>
                    {/* Logo */}
                    {(logoUrl)? <img src={logoUrl} alt={(logoAlt)? logoAlt : "Bee Kind Logo"} width="60%" className='mt-3' /> : null}
                    <div className='row screen-only pt-4 mt-5'>
                        <div className='col-lg-6'>
                            {/* Title */}
                            {(title)? <h2 className='text-uppercase text-left'>{title}</h2> : null}
                        </div>
                        <div className='col-lg-6'>
                            {/* Description */}
                            {(description)?
                            <div className='desc text-left' dangerouslySetInnerHTML={{__html: description}}/>
                            :null}
                        </div>
                    </div>
                </div>
            </section>
            {/* Mobile content */}
            {(isMobile)?
            <div className={(isInView)? 'brand-page__hero-content-bottom d-flex flex-column px-3 in-view' : 'brand-page__hero-content-bottom d-flex flex-column px-3'} style={{backgroundImage: `url(${heroBgMobile2})`}}>
                <div className='slide-up'>
                    {/* Title */}
                    {(title)? <h2 className='pb-5 mb-5 text-uppercase'>{title}</h2> : null}
                    {/* Description */}
                    {(description)? 
                        <div className='desc' dangerouslySetInnerHTML={{__html: description}}/>
                    : null}
                </div>
            </div>
            :null}
        </>
    )
}

export default BeeKindHero