import { Products } from './Products'
import { OurWineMakersNote } from './OurWineMakersNote'
import { ProductDetails } from './ProductDetails'
import { Gallery } from './Gallery'
import { WhatOthersSay  } from "./WhatOthersSay"

export const components = {
    products: Products,
    our_wine_makers_note: OurWineMakersNote,
    product_details: ProductDetails,
    instagram__or_gallery_: Gallery,
    what_others_say: WhatOthersSay
}